<template>
  <v-container fluid>
    <editor @data="save" :pageMode="pageMode" :pageTitle="pageTitle"></editor>
  </v-container>
</template>

<script>
import Editor from "../_components/editor.vue";
export default {
  components: {
    Editor,
  },
  data() {
    return {
      pageMode: "create",
      pageTitle: "Create Approval Template",
    };
  },
  methods: {
    save(data) {
      const url = `/approval_templates`;
      const self = this;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          if(res.ResultCode == 1200){
            self.$router.push('/approval-templates');
          }
        })
        .catch((err) => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>